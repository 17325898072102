.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #a4c5c2;
  border-radius: 50%;
  width: 19.5px;
  height: 19.5px;
  animation: spin 2s linear infinite;
}
.Comploader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #27bdad;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
