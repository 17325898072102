.my_account_tab {
  background-color: #ffffff;
  border: 1px solid #cac8c8;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  padding: 10px 0px;
}

.my_account_tab .nav-item .nav-link {
  color: #75767f;
  background-color: transparent;
  user-select: none;
  cursor: pointer;
  transition: none;
}
.my_account_tab .nav-item .nav-link:hover {
  color: #62a945 !important;
}
.my_account_tab .nav-item .nav-link.active {
  color: #62a945;
  position: relative;
}
.my_account_tab .nav-item .nav-link.active:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 9px solid #44b152;
  border-bottom: 5px solid transparent;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.my_account_main {
  max-width: 870px;
  margin: 100px auto 0 auto;
  padding-bottom: 100px;
}
.update_account_div {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
/* .account_detail_inner form {
  max-width: 625px;
} */
.privacy_policy_modal .modal-header {
  border-bottom: 0;
}
.privacy_policy_modal iframe {
  width: 100%;
  min-height: 450px;
}
.privacy_policy_modal {
  z-index: 9999;
}

.privacy_policy_main img {
  margin: auto;
  display: block;
  height: 250px;
  width: 200px;
}
/* .contact_team_inner form {
  max-width: 625px;
} */
.contact_team_inner .message_section {
  width: 100%;
  margin-top: 20px;
}
.contact_team_inner .message_section textarea:focus {
  box-shadow: unset;
  border-color: #68ab4c;
}
.contact_team_inner .message_section textarea {
  min-height: 115px;
}
.voucher_coupons button {
  padding: 12px 15px;
  width: 100%;
  margin-top: 10px;
}
.privacy_policy_main iframe {
  width: 100%;
  min-height: 350px;
}
.account_detail_inner .phone_number {
  width: 100%;
}
.customer_account_detail .row_section {
  display: unset;
}
.account_detail_inner .custom_input_field {
  min-width: unset !important;
}

@media screen and (max-width: 1500px) and (min-width: 1023px) {
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .account_detail_inner .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .account_detail_inner .row_section {
    flex-wrap: wrap;
  }
  .my_account_main .col-sm-9 {
    flex: 0 0 60%;
    width: 60%;
  }
  .account_detail_inner .row_section .name_input_section,
  .mail_input_section,
  .password_section {
    width: 100%;
  }
  .contact_team_inner .row_section {
    flex-wrap: wrap;
  }
  .contact_team_inner .row_section .name_input_section {
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .my_account_main {
    margin-top: 30px;
  }
  .account_detail_inner .row_section .name_input_section,
  .mail_input_section,
  .password_section {
    width: 100%;
  }
  .tab-content {
    margin-top: 30px;
  }
  .account_detail_inner button {
    width: 100%;
    margin-top: 15px;
  }
  /* .account_detail_inner form {
    margin-bottom: 15px;
  } */
  .contact_team_inner .row_section .name_input_section {
    width: 100%;
  }
  .contact_team_inner form {
    margin-bottom: 15px;
  }
  .contact_team_inner button {
    width: 100%;
    margin-top: 15px;
  }
  .privacy_policy_main {
    margin: 20px 0;
  }
}
