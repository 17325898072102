.promo_business_buttons {
  display: flex;
  gap: 15px;
}
.promo_business_content {
  text-align: center;
}
.promo_business_content p {
  font-size: 14px;
  font-family: auto;
}
.map_frame_section {
  height: 500px;
  width: 100%;
}

@media screen and (max-width: 1500px) and (min-width: 1023px) {
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .PromoBusiness .col-md-8 {
    flex: 0 0 auto;
    width: 100%;
  }
  .PromoBusiness .offset-md-2 {
    margin-left: 0;
  }
  .promo_business_buttons button {
    padding: 12px 25px;
  }
  .PromoBusiness .map_section {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .PromoBusiness .row .col-md-6:last-child {
    margin: 25px 0 40px 0;
  }
}
