.create_account {
  padding-top: 50px;
}
.login_account_inner {
  padding-bottom: 100px;
}
.account_header_logo {
  margin: auto;
  display: block;
  height: 90px;
}
.login_account_inner h3 {
  text-align: center;
  font-size: 20px !important;
  margin-top: 40px;
  margin-bottom: 20px;
}
.row_section {
  display: flex;
  justify-content: center;
  gap: 22px;
  margin-bottom: 15px;
}
input.custom_input_field {
  min-width: 300px;
  border-radius: 0 !important;
  border-bottom: 1px solid #d0d6de !important;
  padding: 0 30px 0 10px !important;
  background-color: transparent !important;
  margin-bottom: 0 !important;
}
input.custom_input_field:focus {
  background-color: transparent !important;
  border-bottom: 1px solid #68ab4c !important;
}
.sign_up_btn {
  background-color: #62a945;
  color: #fff;
  margin: 20px auto 0 auto;
  display: block;
  max-width: 228px;
  min-width: 228px;
}
.already_exits_account {
  text-align: center;
  margin-top: 20px;
}
.already_exits_account span {
  color: #62a945;
  padding-left: 3px;
  text-decoration: underline;
  cursor: pointer;
}
.forgot_username_popup .modal-header .modal-title {
  width: 100%;
  text-align: center;
  font-weight: 600;
}
.forgot_username_popup .modal-footer .tbn_forgot_popup_btn {
  margin: unset;
  max-width: 30%;
  min-width: 30%;
}
.forgot_username_popup .modal-header {
  border-bottom: 0;
}
.forgot_username_popup .modal-footer {
  border-top: 0;
}
.forgot_username_popup .modal-body {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 35px 15px;
  margin: 0 15px;
  text-align: center;
  font-size: 15px;
  border-radius: 10px;
}

.account_mail_icons {
  height: 13px;
  position: absolute;
  right: 5px;
  bottom: 20px;
}
.mail_input_section {
  position: relative;
}
.password_section svg {
  position: absolute;
  right: 7px;
  top: 25px;
  color: #74b361;
  font-size: 17px;
}
.password_section {
  position: relative;
}
.forget_password {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 25px;
}
.forget_password span {
  color: #62a945;
  padding-left: 3px;
  text-decoration: underline;
  cursor: pointer;
}
.account_forgot_username_inner {
  padding-bottom: 100px;
}
.account_forgot_username_inner h3 {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 30px;
}
.account_forgot_username {
  padding-top: 90px;
}
.account_forgot_username_inner .confirmation_code_para {
  max-width: 350px;
  text-align: center;
  margin: 20px auto 10px;
}

@media screen and (max-width: 1500px) and (min-width: 1023px) {
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .login_account_inner h3 {
    margin-top: 40px;
  }
  .login_account_inner form {
    margin: 35px 0;
  }
}
