.services_box {
  background-color: #fff;
  border: 1px solid #cac8c8;
  margin-bottom: 25px;
  padding: 20px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: 0.5s;
}
.services_box:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.5s;
}
.services_box .services_name h2 {
  margin-bottom: 0;
}
.services_name {
  flex: 1;
}
