.App {
  position: relative;
  min-height: 100vh;
}
.App:before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 170px;
  height: 69px;
  background-image: url("/src/assets/Shape-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 999;
}
.App:after {
  content: "";
  bottom: 0;
  right: 0;
  position: absolute;
  width: 170px;
  height: 69px;
  background-image: url("/src/assets/Shape-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
