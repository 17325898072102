.favourites_main {
  padding-top: 30px;
}
.favourites_main_head {
  text-align: center;
}
.favourites_info_inner {
  max-width: 380px;
}
.favourites_verify_btn {
  padding: 12px 55px;
  max-height: 55px;
  font-size: 15px;
}
.favourites_info_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 25px;
}
.verify_conformation h4 {
  margin: 0;
  font-size: 14px !important;
  text-transform: uppercase;
  font-weight: 800;
}
.verify_conformation span {
  font-size: 27px;
  font-weight: 900;
  color: #959fac;
}
.verify_conformation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.favourites_price_info_inner h4 {
  text-transform: uppercase;
  font-size: 15px !important;
}
.favourites_price_info_inner h1 {
  font-size: 40px !important;
  color: #62a945;
}
.favourites_info_inner p {
  font-size: 15px;
}
.favourites_price_info_inner p {
  font-size: 16px;
  font-weight: 600;
}
.favourites_main_flex {
  display: flex;
  justify-content: center;
  gap: 90px;
  margin: 40px 0 180px 0;
}
.favourites_price_info {
  max-width: 395px;
  border: 1px solid #cac8c8;
  padding: 25px;
  border-radius: 10px;
}
.favourites_price_info_inner {
  text-align: center;
  border-bottom: 1px solid #edf0f5;
}
.favourites_price_percent_info p {
  text-transform: uppercase;
  max-width: 140px;
  text-align: center;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 6px;
}
.favourites_price_percent_info {
  margin-top: 15px;
}
.favourites_price_percent_info span {
  font-size: 20px;
  font-weight: 600;
  color: #838e9d;
  text-align: center;
  display: block;
  background-color: #f0f3f7;
  max-width: 100px;
  padding: 7px 0;
  border-radius: 10px;
  margin: auto;
}
.price_percent {
  max-width: 145px;
}
.favourites_price_percent_main {
  display: flex;
  gap: 45px;
}
.favourites_price_percent_info.availbe_discount p {
  max-width: 137px;
}
.availbe_discount_percent span {
  background: transparent;
  color: #333333;
  font-size: 30px;
  padding: 0;
}
.favourites_info_inner h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 13px;
}
.price_percent_info_after:after {
  content: "";
  background-color: #edf0f5;
  width: 1.5px;
  height: 80px;
  position: absolute;
  right: -25px;
  top: 0;
}
.price_percent_info_after {
  position: relative;
}

@media screen and (max-width: 1500px) and (min-width: 1023px) {
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .favourites_main_flex {
    gap: 30px;
  }
  .favourites_price_info {
    padding: 55px 25px 0 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .favourites_main_flex {
    flex-wrap: wrap;
    margin: 70px 0 120px 0;
  }
}
