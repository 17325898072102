.Vouchers_box {
  background-color: #fff;
  border: 1px solid #cac8c8;
  margin-bottom: 25px;
  padding: 30px 30px;
  border-radius: 10px;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;
  user-select: none;
  position: relative;
}
.Vouchers_box h2 {
  margin-bottom: 5px;
}
.Vouchers_box:hover {
  color: #62a945;
  border-color: #62a945;
  transition: 0.5s;
}
.remove {
  position: absolute;
  right: 10px;
  top: 10px;
}
