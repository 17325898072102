button,
.button {
  border: 0;
  padding: 12px 30px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}
.black_btn,
.black_btn:focus {
  background-color: #303238;
  color: #fff;
  transition: 0.5s;
  border: none;
  box-shadow: none;
}
.black_btn:hover {
  background-color: #62a945;
  transition: 0.5s;
}
.green_btn:hover {
  background-color: #303238;
  transition: 0.5s;
}
.green_btn,
.green_btn:focus {
  background-color: #62a945;
  color: #fff;
  transition: 0.5s;
  border: none;
  box-shadow: none;
}
.green_border {
  background: transparent;
  border: 1px solid #62a945;
  color: #62a945;
  transition: 0.5s;
}
.green_border:hover {
  border: 1px solid #303238;
  color: #303238;
  transition: 0.5s;
}

.black_border {
  background: transparent;
  border: 1px solid #303238;
  color: #303238;
  transition: 0.5s;
}
.black_border:hover {
  border: 1px solid #62a945;
  color: #62a945;
  transition: 0.5s;
  background-color: transparent;
}
.black_border:focus {
  border: 1px solid #62a945;
  box-shadow: none;
  color: #62a945;
  background-color: transparent;
}
/* resposive start */
@media screen and (max-width: 1024px) and (min-width: 991px) {
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  button {
    padding: 12px 18px;
  }
}

/* resposive end */
