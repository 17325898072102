@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lato:wght@300;400;700;900&display=swap");
body {
  font-family: "Lato", sans-serif;
}
a {
  color: #0d6efd;
  text-decoration: none;
}
a:hover {
  color: #62a945 !important;
}
img {
  max-width: 100%;
}

body {
  font-size: 13px;
}

.loader {
  margin: auto;
}

h1 {
  font-size: 21px !important;
  font-weight: 600;
}

h2 {
  font-size: 18px !important;
  font-weight: 700;
}

h3 {
  font-size: 16px !important;
  font-weight: 700;
}

h4 {
  font-size: 25px !important;
  font-weight: 600;
}

h5 {
  font-size: 20px;
  font-weight: 500;
}

h6 {
  font-size: 18px;
  font-weight: 600;
}
.btn_disabled {
  opacity: 0.5;
}

label {
  font-size: 14px !important;
  font-weight: 600;
  color: #0e393a;
  margin-bottom: 7px !important;
  text-transform: capitalize;
}
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
select {
  font-size: 14px !important;
  color: #404040 !important;
  font-weight: 400 !important;
  width: 100% !important;
  border-radius: 35px !important;
  padding: 15px 30px 15px 30px !important;
  margin-bottom: 20px !important;
  height: 55px !important;
  background-color: #ebebeb !important;
  border: none !important;
}
button {
  outline: 0;
}

select {
  /* background-image: url("/src/assets/select_arrow.png") !important; */
  background-position: right 20px center !important;
  background-size: 10px 7px !important;
}

::placeholder {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
select:focus {
  color: unset !important;
  background-color: #ebebeb !important;
  border-color: unset !important;
  box-shadow: none !important;
}
.img_div {
  text-align: center;
}
.inner_body {
  padding: 120px 0;
}
.inner_pg {
  padding-top: 35px;
  padding-bottom: 50px;
  min-height: 100vh;
}
.btn_div {
  text-align: center;
  margin-top: 30px;
}
.inner_pg .top_heading {
  text-align: center;
  margin-bottom: 30px;
}
.btns_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  gap: 10px;
}
.btns_group .green_btn {
  flex: 0.5;
}
.btns_group .black_btn {
  flex: 1;
}
.bg_img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}
.business_info_para p {
  background-color: transparent !important;
}
.align-items-center {
  align-items: center;
}
/* .react-multi-carousel-list {
  padding-bottom: 20px;
} */
button.react-multiple-carousel__arrow {
  opacity: 0;
}
.Carousel_sec .react-multi-carousel-list.carousel-container {
  max-width: 620px;
  margin: 0 auto;
}
.btn_modal .modal-header .btn-close,
.btn-close {
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border: 1px solid #303238;
  background-color: #62a945;
  opacity: 1;
  position: absolute;
  right: 0px;
  top: 0px;
  text-align: center;
  padding: 0;
  border: none;
}
.btn_modal .modal-header {
  padding: 0;
  border: none;
}
.btn_modal .modal-footer {
  justify-content: center;
  border: none;
}
.modal-body ul {
  padding: 5px 0;
  margin: 0;
}
.modal-body ul li {
  list-style: none;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-body ul li span {
  font-weight: 600;
  font-size: 16px;
}
.Toastify {
  position: relative;
  z-index: 99999;
}
.btn_modal .modal-footer .btn-primary {
  flex: 1;
}
.bg_body {
  background-image: url("/src/assets/body_bg.png");
  background-repeat: no-repeat;
  background-position: top;
  background-color: #fff;
  background-size: cover;
  min-height: 100vh;
}
.bg_body.business_bg_body {
  box-shadow: 0px 0px 5px 1px #c5c5c5;
  position: relative;
  z-index: 999;
}
.avenue_modal .modal-content {
  padding: 30px;
}
.avenue_modal .modal-content::-webkit-scrollbar {
  width: 0;
}
.avenue_modal .modal-content {
  padding: 30px;
  max-height: 400px;
  overflow: scroll;
}
.avenue_modal .modal-header {
  right: 0;
  top: 0;
}
.react-multiple-carousel__arrow {
  z-index: -1;
}
.contact_team_inner form {
  max-width: 100%;
}
.Carousel_sec_category_img .react-multi-carousel-list {
  justify-content: center;
}
.category_loader {
  margin-top: 25px;
}
.business_listing_box .services_icon img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.business_listing_box {
  display: flex !important;
  align-items: flex-start !important;
  height: 100%;
  padding-bottom: 0 !important;
}
.business_listing_main .row .col-lg-4 {
  margin-bottom: 30px;
}
.absolute.custom_arrow_pre_slide,
.absolute.custom_arrow_next_slide {
  position: absolute;
  top: 50%;
  transform: translateY(50%);
  cursor: pointer;
  z-index: 2;
}
.absolute.custom_arrow_pre_slide {
  left: 0;
}
.absolute.custom_arrow_next_slide {
  right: 0;
}

/* responsive start */
@media screen and (max-width: 5120px) and (min-width: 1680px) {
  body .restaurant_info_para p {
    font-size: 32px !important;
  }
  .business_schedule .top_heading {
    font-size: 40px !important;
  }
  .row.business_row_content .bg_img {
    height: 650px !important;
  }
  .calendar_detail_main {
    padding: 25px 25px !important;
    height: 650px !important;
  }
  .calendar_detail_open {
    margin-bottom: 50px !important;
  }
  .calendar_detail_schedule {
    padding: 55px 0 !important;
  }
  .business_listing_box .services_icon img {
    width: 115px !important;
    height: 115px !important;
  }

  .business_listing_box .services_name h2 {
    font-size: 27px !important;
  }
  .ChildCategory h1 {
    font-size: 40px !important;
  }
  .business_listing_box .services_name p {
    font-size: 20px;
  }
  .business_listing_box {
    display: flex !important;
    align-items: center !important;
    gap: 25px !important;
  }
  body .restaurant_image img {
    width: 1750px !important;
    height: 1250px !important;
  }
  .container {
    max-width: 80%;
    margin: 0 auto;
  }
  /* .header_logo img {
    height: 65px !important;
  } */
  header.header {
    max-width: unset !important;
  }
  .services_box {
    padding: 45px 15px !important;
  }
  .services_box .services_icon img {
    width: 105px;
  }
  .services_box .services_name h3 {
    font-size: 27px !important;
  }
  .selection_region_inner a {
    font-size: 30px !important;
  }
  .header_right_side_inner button {
    padding: 15px 35px !important;
    font-size: 16px !important;
  }
  .selection_region_inner a:first-child:after {
    height: 2px !important;
    width: 10px !important;
    right: -15px !important;
    top: 24px !important;
  }
  body .header_search {
    height: 50px !important;
  }
  body .header_right_side_inner input {
    font-size: 22px !important;
  }
  body .header_search svg {
    font-size: 25px !important;
  }
  .nofication_icon {
    width: 45px !important;
    height: 45px !important;
    line-height: 40px !important;
    font-size: 21px !important;
  }
  .nofication_indicate:after {
    height: 10px !important;
    width: 10px !important;
    right: 11px !important;
    top: 8px !important;
  }
  .region_town_selection {
    min-width: 300px !important;
    max-width: 320px !important;
    right: 0 !important;
    top: 45px !important;
    min-height: auto !important;
    max-height: 395px !important;
  }
  .Carousel_sec_category_img .slide_button .previous_slide svg,
  .Carousel_sec_category_img .slide_button .next_slide svg {
    font-size: 75px !important;
  }
  .Carousel_sec_category_img .slide_button .previous_slide {
    left: 290px !important;
  }
  .Carousel_sec_category_img .slide_button .next_slide {
    right: 290px !important;
  }
  .broadbeach_casual_slider .react-multi-carousel-track li {
    width: 310px !important;
  }
  .Carousel_sec .react-multi-carousel-list.carousel-container {
    max-width: 1290px !important;
  }
  .broadbeach_casual_slider
    .react-multi-carousel-track
    li
    .broadbeach_casual_slider_content
    p {
    font-size: 20px;
    max-width: unset;
    padding: 0 12px;
  }
  .broadbeach_casual_slider .slide_button .previous_slide {
    left: 200px;
  }
  .broadbeach_casual_slider .slide_button .next_slide {
    right: 200px;
  }
  .broadbeach_casual_slider .slide_button .previous_slide svg,
  .broadbeach_casual_slider .slide_button .next_slide svg {
    font-size: 45px !important;
  }
  .restaurant_bg_img {
    margin: 10px !important;
  }
  .feature_promotions_content {
    max-height: 35px;
    min-height: 35px;
  }
  .feature_promotions_content h3 {
    font-size: 20px !important;
    margin-bottom: 7px !important;
  }
  /* .restaurant_bg_img {
    width: 100% !important;
  } */
}
@media screen and (max-width: 3400px) and (min-width: 1680px) {
  body .restaurant_info_para p {
    font-size: 32px !important;
  }
  .feature_promotions_slider .slider_cols {
    flex: 0 0 auto;
    width: 100%;
    margin-left: 0;
  }
  .row.business_row_content .bg_img {
    height: 400px !important;
  }
  .calendar_detail_main {
    padding: 25px 25px !important;
    height: 400px !important;
  }
  .calendar_detail_open {
    margin-bottom: 30px !important;
  }
  .calendar_detail_schedule {
    padding: 30px 0 !important;
  }

  .Carousel_sec_category_img .slide_button .previous_slide svg,
  .Carousel_sec_category_img .slide_button .next_slide svg {
    font-size: 45px !important;
  }
  .Carousel_sec_category_img .slide_button .previous_slide {
    left: 40px !important;
  }
  .Carousel_sec_category_img .slide_button .next_slide {
    right: 40px !important;
  }
  body .restaurant_image img {
    height: 650px !important;
    object-fit: cover !important;
    width: 1010px !important;
  }
  .broadbeach_casual_slider .slide_button .previous_slide {
    left: -90px;
  }
  .broadbeach_casual_slider .slide_button .next_slide {
    right: -90px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 991px) {
  .bg_body.business_bg_body,
  .bg_body.business_bg_body .inner_pg {
    min-height: auto;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .feature_promotions_slider .slider_cols {
    flex: 0 0 auto;
    width: 100%;
    margin-left: 0;
  }
  h3 {
    font-size: 14px !important;
  }
  .bg_body.business_bg_body,
  .bg_body.business_bg_body .inner_pg {
    min-height: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .region_town_selection {
    right: unset !important;
    left: 0;
  }
  .absolute.custom_arrow_pre_slide,
  .absolute.custom_arrow_next_slide {
    top: 60%;
    transform: translateY(20%);
  }
  .home_main {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .bg_body.business_bg_body,
  .bg_body.business_bg_body .inner_pg {
    min-height: auto;
  }
}

/* responsive end */
