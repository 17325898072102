footer {
  width: fit-content;
  background-color: #303238;
  border-top-right-radius: 60px;
}
footer ul {
  margin: 0 56px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
footer ul li {
  display: inline-block;
  margin: 0 8px;
}
footer ul li:first-child {
  margin-left: 0;
}
footer ul li a {
  color: #fff !important;
  padding: 15px 0;
  display: block;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  opacity: 0.5;
  transition: 0.5s;
}
footer ul li a:hover {
  opacity: 1;
  transition: 0.5s;
}
.footer_menu {
  display: flex;
  align-items: center;
  gap: 10px;
}
footer.footer_area {
  position: absolute;
  bottom: 0;
  left: 0;
}
/* resposive start */
@media screen and (max-width: 1024px) and (min-width: 991px) {
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  footer ul {
    margin: 0 0px 0 15px;
    flex-wrap: wrap;
    position: relative;
    z-index: 9;
  }
  footer ul li:first-child {
    margin-left: 8px;
  }
  footer.footer_area {
    position: unset;
  }
}

/* resposive end */
