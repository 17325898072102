.voucher_coupon_inner h4 {
  text-align: center;
}
.voucher_coupon_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0 100px 0;
  flex-direction: column;
}
.voucher_limied_time {
  margin-top: 20px;
}
.voucher_coupon {
  margin: 21px 0 100px 0;
}
.voucher_coupon_box .price_percent_info_after {
  max-width: 112px;
}
.voucher_coupon_box .price_percent_info_after:after {
  right: -35px;
}
.voucher_coupon_box .price_percent span {
  background: transparent;
  font-size: 24px;
  padding: 3px 0;
}
.voucher_coupon_box .price_percent_info_after p {
  margin: auto;
}
span.star_span {
  font-weight: 800;
  color: #e78200;
}
