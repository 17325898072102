.create_account {
  padding-top: 50px;
}
.create_account_inner {
  padding-bottom: 90px;
}
.account_header_logo {
  margin: auto;
  display: block;
  height: 90px;
}
.create_account_inner h3 {
  text-align: center;
  font-size: 20px !important;
  margin-top: 40px;
}
.row_section {
  display: flex;
  justify-content: center;
  gap: 22px;
  margin-bottom: 15px;
}
input.custom_input_field {
  min-width: 300px;
  border-radius: 0 !important;
  border-bottom: 1px solid #d0d6de !important;
  padding: 0 30px 0 10px !important;
  background-color: transparent !important;
  margin-bottom: 0 !important;
}
input.custom_input_field:focus {
  background-color: transparent !important;
  border-bottom: 1px solid #68ab4c !important;
}
.sign_up_btn {
  background-color: #62a945;
  color: #fff;
  margin: 20px auto 0 auto;
  display: block;
}
.already_exits_account {
  text-align: center;
  margin-top: 20px;
}
.already_exits_account span {
  color: #62a945;
  padding-left: 3px;
  text-decoration: underline;
  cursor: pointer;
}
.account_name_icons {
  height: 17px;
  position: absolute;
  right: 5px;
  bottom: 16px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.name_input_section {
  position: relative;
}

.account_mail_icons {
  height: 13px;
  position: absolute;
  right: 5px;
  bottom: 20px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.mail_input_section {
  position: relative;
}

.password_section svg {
  position: absolute;
  right: 7px;
  top: 25px;
  color: #74b361;
  font-size: 17px;
}
.password_section {
  position: relative;
}

/* responsive strat */

@media screen and (max-width: 1500px) and (min-width: 1023px) {
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .creat_account_fields .row_section .creation_input_section {
    width: 100%;
  }
  .account_header_logo {
    height: 70px !important;
  }
  .row_section {
    flex-wrap: wrap !important;
    gap: 0 !important;
    margin-bottom: 0 !important;
  }
  input.custom_input_field {
    min-width: 365px;
  }
  .create_account_inner {
    padding-top: 50px;
  }
}

/* responsive end */
