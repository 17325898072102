.Carousel_sec {
  position: relative;
}
.slide_button {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
}
.slide_button .previous_slide,
.slide_button .next_slide {
  cursor: pointer;
}
.slide_button .previous_slide svg,
.slide_button .next_slide svg {
  color: #cac8c8;
  font-size: 30px;
}
.slide_button .previous_slide {
  position: absolute;
  left: 20px;
}
.slide_button .next_slide {
  position: absolute;
  right: 20px;
}
.broadbeach_casual_inner h2 {
  font-size: 22px !important;
  text-align: center;
  margin-bottom: 30px;
}
.broadbeach_casual_inner {
  padding-top: 30px;
  padding-bottom: 100px;
  min-height: 100vh;
}
.broadbeach_casual_slider_content {
  background-color: #fff;
  border: 1px solid #aca6a6;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 10px;
  padding: 15px 15px;
  transition: 0.5s;
}
.broadbeach_casual_slider_content:hover {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  transition: 0.5s;
}
.broadbeach_casual_slider p {
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;
  max-width: 148px;
  user-select: none;
  margin: 0 auto;
}
.feature_promotions_section {
  margin-top: 20px;
}
.feature_promotions_section p {
  margin-bottom: -10px;
  font-size: 17px;
}
.feature_promotions_content {
  text-align: center;
}
.feature_promotions_content h3 {
  text-transform: uppercase;
  font-size: 25px !important;
  margin-top: 10px;
}
.restaurant_image {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
/* .feature_promotions_slider {
  margin-top: 20px;
} */
.broadbeach_new_btn {
  background-color: #62a945;
  color: #fff;
  padding: 12px 55px;
  transition: 0.5s;
}
.broadbeach_new_btn:hover {
  background-color: #303238;
  transition: 0.5s;
}
.broadbeach_Popular_btn {
  background-color: #303238;
  color: #fff;
  padding: 12px 40px;
  transition: 0.5s;
}
.broadbeach_Popular_btn:hover {
  background-color: #62a945;
  transition: 0.5s;
}
.broadbeach_casual_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 25px;
}
.restaurant_bg_img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.restaurant_image img {
  height: 410px;
  object-fit: cover;
  width: 540px !important;
}
.broadbeach_casual_slider_content.item-active {
  border: 1px solid #45a948fc;
  transition: 0.5s;
}
.broadbeach_casual_slider_content.item-active p {
  color: #62a945;
  transition: 0.5s;
}
.category_loader .loader {
  margin: auto;
}
.learn_more_category_btn {
  border-radius: 0;
  transition: 0.5s;
  position: absolute;
  bottom: 0;
  right: 0;
}
.learn_more_category_btn {
  border-radius: 0;
  transition: 0.5s;
}
.learn_more_category_btn:hover {
  transition: 0.5s;
  background-color: #62a945;
  color: #fff;
}
.restaurant_info_para {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
/* .restaurant_info_para:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #00000078;
} */
.restaurant_info_para p {
  text-align: center;
  color: #fff;
  font-size: 22px !important;
  position: relative;
}

/* resposive start */
@media screen and (max-width: 1024px) and (min-width: 991px) {
  .slide_button .previous_slide {
    left: -30px !important;
  }
  .slide_button .next_slide {
    right: -30px !important;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .slide_button .previous_slide {
    left: -30px !important;
  }
  .slide_button .next_slide {
    right: -30px !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .restaurant_bg_img img {
    height: 240px !important;
    width: 250px !important;
  }
  .Carousel_sec_category_img .slide_button .previous_slide {
    left: 0;
  }
  .Carousel_sec_category_img .slide_button .next_slide {
    right: 0px;
  }
}

/* resposive end */
