.home_header_section {
  display: flex;
  justify-content: center;
  gap: 450px;
  margin-bottom: 30px;
}
.home-logo {
  height: 47px;
}
.gate_way {
  height: 32px;
}
.home_header_content {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.home_header_content p {
  max-width: 240px;
  text-align: end;
  font-size: 13px;
}
.create_account_btn {
  background-color: #303238;
  color: #fff;
  transition: 0.5s;
}
.create_account_btn:hover {
  background-color: #62a945;
  transition: 0.5s;
}
.sign_in_btn {
  background-color: #62a945;
  color: #fff;
  transition: 0.5s;
}
.sign_in_btn:hover {
  background-color: #303238;
  transition: 0.5s;
}
.home_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
}

/* responsive strat */

@media screen and (max-width: 1500px) and (min-width: 1023px) {
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .home_header_section {
    display: flex;
    justify-content: space-between;
    gap: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .home_header_section {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 50px;
  }
  .home-logo {
    height: 28px;
  }
  .home_bottom {
    margin-bottom: 60px;
  }
}

/* responsive end */
