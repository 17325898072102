.Carousel_sec {
  position: relative;
}
.slide_button {
  position: absolute;
  left: 0;
  right: 0;
  top: 21px !important;
}
.slide_button .previous_slide,
.slide_button .next_slide {
  cursor: pointer;
}
.slide_button .previous_slide svg,
.slide_button .next_slide svg {
  color: #cac8c8;
  font-size: 30px;
}
.slide_button .previous_slide {
  position: absolute;
  left: 20px;
}
.slide_button .next_slide {
  position: absolute;
  right: 20px;
}
.broadbeach_casual_inner h2 {
  font-size: 22px !important;
  text-align: center;
  margin-bottom: 30px;
}
.broadbeach_casual_inner {
  padding-top: 30px;
  padding-bottom: 100px;
  min-height: 100vh;
}
.broadbeach_casual_slider_content {
  background-color: #fff;
  border: 1px solid #aca6a6;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 10px;
  padding: 15px 15px;
  position: relative;
  max-height: 75px;
  min-height: 70px;
}
.broadbeach_casual_slider p {
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;
  max-width: 148px;
  user-select: none;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.feature_promotions_section p {
  margin: 0;
  font-size: 17px;
}
.feature_promotions_content {
  text-align: center;
}
.feature_promotions_content h3 {
  text-transform: uppercase;
  font-size: 25px !important;
  margin-top: 10px;
  margin-bottom: 20px;
}
.restaurant_image {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
/* .feature_promotions_slider {
  margin-top: 20px;
} */
.broadbeach_new_btn {
  background-color: #62a945;
  color: #fff;
  padding: 12px 55px;
}
.broadbeach_Popular_btn {
  background-color: #303238;
  color: #fff;
  padding: 12px 40px;
}
.broadbeach_casual_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 25px;
}
.restaurant_image img {
  height: 310px;
}
.broadbeach_casual_slider_content.item-active {
  border: 1px solid #45a948fc;
  transition: 0.5s;
}
.broadbeach_casual_slider_content.item-active p {
  color: #62a945;
  transition: 0.5s;
}

.category_loader .loader {
  margin: auto;
}
.Carousel_sec_category_img {
  position: relative;
}
.Carousel_sec_category_img .slide_button {
  top: 57% !important;
  transform: translateY(-50%);
  z-index: 2;
}
.Carousel_sec_category_img .slide_button .previous_slide {
  left: 180px;
}
.Carousel_sec_category_img .slide_button .next_slide {
  right: 180px;
}

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  /* .slide_button .next_slide {
    right: -35px;
  }
  .slide_button .previous_slide {
    left: -35px;
  } */
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .slide_button .next_slide {
    right: -35px;
  }
  .slide_button .previous_slide {
    left: -35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .broadbeach_casual_slider_content {
    margin: 0 60px !important;
  }
  .slide_button .next_slide {
    z-index: 1;
  }
  .slide_button .previous_slide {
    z-index: 1;
  }
  .Carousel_sec_category_img .slide_button .previous_slide {
    left: 0;
  }
  .Carousel_sec_category_img .slide_button .next_slide {
    right: 0px;
  }
}
