body {
  margin: 0;
  /* height: 100%; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toggle_button {
  border: none;
  background: no-repeat;
  cursor: pointer;
}
.toggle_button:focus {
  outline: none;
}
.toggle_button:focus-visible {
  outline: none;
}
