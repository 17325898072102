.PromoBusiness .TextButton .btns_group {
  flex-direction: row-reverse;
}
.business_info_para {
  font-size: 14px;
}
.promo_business_btn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.schedule_sheet_info p {
  font-size: 14px;
  margin-bottom: 5px;
}
.schedule_sheet_info a {
  color: #333333;
  font-weight: 600;
}
.tell_num {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
.call_now_btn {
  text-transform: capitalize;
  flex: 1.1;
  width: 100%;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}
.book_online_btn:hover {
  color: #fff !important;
}
.schedule_sheet_bottom {
  display: flex;
  gap: 13px;
  align-items: center;
  margin-top: 16px;
}
.schedule_sheet_info {
  margin-top: 22px;
}
/* .business_map_section {
  margin-top: 30px;
} */
.business_map_section {
  margin-top: -3px;
}
/* time schedule area */

.schedule_open_time {
  display: flex;
  gap: 26px;
  user-select: none;
  margin-bottom: 8px;
}
.schedule_open_time span:first-child {
  text-transform: uppercase;
  font-weight: 600;
  color: #62a945;
  max-width: 150px;
  min-width: 60px;
}
.schedule_detail_inner {
  display: flex;
  gap: 19.1px;
  border-bottom: 1.5px solid #edf0f5;
  padding: 13px 0 15px 0;
}
.schedule_open_time span {
  font-weight: 800 !important;
}
.schedule_detail_inner span:first-child {
  max-width: 150px;
  min-width: 65px;
  font-weight: 500;
  user-select: none;
}
.show_schedule_sheet .schedule_detail_inner:last-child {
  border-bottom: 0;
}
.schedule_time_section {
  background-color: #fff;
  border: 1px solid #cac8c8;
  padding: 30px 20px;
  border-radius: 15px;
  max-height: 261px;
  min-height: 255px;
  margin-top: 0;
}

.child_category_box {
  background-color: #fff;
  border: 1px solid #cac8c8;
  margin-bottom: 25px;
  padding: 30px 30px;
  border-radius: 10px;
  text-align: center;
  min-height: 120px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  max-height: 155px;
  min-height: 150px;
}
.child_category_box:hover {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  transition: 0.5s;
}
.ChildCategory h1 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.child_category_box h2 {
  margin-bottom: 5px;
}
.child_category_box p {
  line-height: 1.2;
  margin-bottom: 0px;
}

/* time schedule area */

/* time schedule area second */
.calendar_detail_open {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.calendar_head_openings {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}
.calendar_head_openings span {
  font-weight: 700 !important;
}
.calendar_open_head_title {
  max-width: 120px;
  min-width: 90px;
  text-transform: uppercase;
  color: #62a945;
  font-weight: 700 !important;
}
.calendar_detail_schedule {
  display: flex;
  border-bottom: 1px solid #c6c1c1;
  padding-bottom: 10px;
  padding-top: 18px;
  justify-content: space-between;
}

.calendar_detail_schedule_inner {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.calendar_detail_main {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #cac8c8;
  border-radius: 10px;
  padding: 25px 25px;
  user-select: none;
}
.calendar_detail_main .calendar_detail_inner {
  width: 100%;
}

.calendar_schedule_all_day {
  border: none;
  padding-bottom: 0;
}
/* .calendar_detail_inner {
  padding: 0;
  max-width: 300px;
  min-width: 380px;
} */
.calendar_open_title {
  max-width: 120px;
  min-width: 90px;
}
/* time schedule area second */

@media screen and (max-width: 1500px) and (min-width: 1023px) {
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .business_schedule .col-md-8 {
    flex: 0 0 auto;
    width: 100%;
  }
  .business_schedule .offset-md-2 {
    margin-left: 0;
  }
  .promo_business_btn button,
  .schedule_sheet_bottom button {
    padding: 12px 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .business_schedule .row .col-md-6:last-child {
    margin-top: 50px;
  }
  .map_frame_section {
    height: 400px;
  }
  .schedule_sheet_bottom {
    display: block;
  }
  .schedule_sheet_info a,
  .schedule_sheet_info button {
    width: 100%;
    margin-bottom: 10px;
  }
}
