header.header {
  padding: 20px 0 0 0;
  max-width: 970px;
  margin: 0 auto;
  z-index: 999;
}
header.header .row {
  align-items: center;
}
/* .header_logo img {
  height: 25px;
} */
.header_content {
  display: flex;
  justify-content: space-between;
}

.header_right_side_inner input {
  font-size: 11px !important;
  border-radius: 0 !important;
  background: none !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.header_right_side_inner input:focus-visible {
  outline: 0;
}
.header_right_side_inner input:focus {
  background: transparent !important;
}
.header_search {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  height: 33px;
  padding: 0 15px;
  gap: 5px;
  border: 1px solid #cac8c8;
}
.header_search svg {
  cursor: pointer;
}
.help_button {
  background-color: #62a945;
  border: none;
  color: #fff;
  font-size: 12px;
  border-radius: 35px;
  padding: 7px 21px;
  transition: 0.5s;
  line-height: normal;
}
.help_button:hover {
  background-color: #303238;
  transition: 0.5s;
}
.logout_brn {
  background-color: #303238;
  border: none;
  color: #fff;
  font-size: 12px;
  border-radius: 35px;
  padding: 7px 21px;
  transition: 0.5s;
  line-height: normal;
}
.logout_brn:hover {
  background-color: #62a945;
  transition: 0.5s;
}
.header_right_side_inner {
  display: flex;
  gap: 10px;
  margin-top: 12px;
  justify-content: flex-end;
  align-items: center;
}
.nofication_icon {
  border: 1px solid;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
}
.nofication_indicate:after {
  content: "";
  background-color: red;
  height: 8px;
  width: 8px;
  position: absolute;
  border-radius: 50%;
  right: 6px;
  top: 6px;
}
.nofication_indicate {
  position: relative;
}
.header_logo a {
  cursor: pointer;
}
.selection_region_inner {
  display: flex;
  gap: 20px;
  justify-content: end;
  position: relative;
}
.selection_region_inner a {
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  transition: 0.5s;
  user-select: none !important;
}
.selection_region_inner a:hover {
  color: #62a945 !important;
  transition: 0.5s;
}
.selection_region_inner a:first-child:after {
  content: "";
  background-color: #333333;
  height: 1.5px;
  width: 8px;
  position: absolute;
  right: -14px;
  top: 11px;
}
.selection_region_inner a:first-child {
  position: relative;
}
.region_town_selection {
  position: absolute;
  right: -17px;
  top: 25px;
  background-color: #fff;
  border: 1px solid #cac8c8;
  min-width: 195px;
  max-width: 300px;
  z-index: 9;
  min-height: auto;
  max-height: 330px;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  padding: 20px 12px 30px 12px;
}
.region_town_selection::-webkit-scrollbar {
  width: 0;
}
.region_town_selection ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.region_town_selection ul li {
  font-size: 15px;
  border-bottom: 1px solid #cac8c8;
  padding: 10px 13px;
  cursor: pointer;
  transition: 0.5s;
}
.region_town_selection ul li:hover {
  color: #62a945;
  transition: 0.5s;
}
.region_town_selection ul li:last-child {
  border-bottom: none;
}
.active_selection {
  color: #62a945 !important;
}
.selection_main_tr {
  display: flex;
  flex-direction: column;
}
.selection_main_tr td b {
  font-weight: 900;
}
.selection_main_key_value {
  border-bottom: 1px solid #c3c1c1;
  padding: 12px 0 5px 0;
}
.selection_main_key_value p {
  margin: 0;
  padding-left: 15px;
  transition: 0.5s;
}
.selection_main_key_value p:hover {
  cursor: pointer;
  color: #62a945;
  transition: 0.5s;
}
.selection_main_tr .selection_head {
  padding-top: 4px;
}
.loader_area {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select_region_heading {
  font-size: 15px !important;
  text-align: center;
  color: #62a945;
  margin-bottom: 15px;
}
.area_according_values p {
  margin: 0;
}
.region_town_selection ul .area_according_values:last-child {
  border-bottom: 1px solid #cac8c8;
}

/* Mobile Navigation Menu */

/* .region_selection_notify {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  margin-top: 23px;
} */
.header_mobile_menu_list .region_selection_notify h4 {
  font-size: 17px !important;
  margin-bottom: 0;
}
.menu_content_main {
  background-color: #fff;
  border: 1px solid #cac8c8;
  padding: 25px 15px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.menu_content_inner .header_search input {
  font-size: 13px !important;
  border-radius: 0 !important;
  background: none !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.menu_content_inner .header_search input:focus-visible {
  outline: 0;
}
.menu_content_inner .header_search {
  margin-top: 25px;
  height: 40px;
}
.menu_content_inner .menu_bottom_btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 18px;
}
.menu_content_inner .menu_bottom_btn button {
  padding: 10px 21px;
}
.menu_content_inner .header_search svg {
  font-size: 17px;
}
.menu_content_inner .header_logo {
  margin-bottom: 30px;
}
.region_town_selection .error_msg_select_region {
  text-align: center;
  margin: 0;
  color: #62a945;
  font-size: 14px;
}
.mobile_menu_navigation .region_selection_notify {
  margin-top: 0;
}
.header_mobile_menu_list {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  flex-direction: row-reverse;
  margin-top: 15px;
  justify-content: space-between;
}
.header_mobile_menu_list .menu_nofication {
  display: flex;
  flex-direction: inherit;
  gap: 10px;
}
.header_mobile_menu_list .region_selection_notify {
  margin-top: 0;
}
/* resposive start */
@media screen and (max-width: 1024px) and (min-width: 991px) {
  header.header {
    padding: 55px 0 0 0;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  header.header {
    padding: 55px 0 0 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .header_mobile_section {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  /* .header_mobile_section .header_logo img {
    height: 18px;
  } */
  .header_logo img {
    height: 25px;
  }
  .header_mobile_section .header_search input {
    font-size: 9px !important;
    border-radius: 0 !important;
    background: none !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
  }
  .header_mobile_section .header_search input:focus-visible {
    outline: 0;
  }
  .header_mobile_section .header_search {
    margin-left: 15px;
    flex: 1;
  }
  header.header .col-sm-6:first-child {
    width: 75%;
  }

  header.header .col-sm-6:last-child {
    width: 25%;
  }

  .menu_content_main {
    position: absolute;
    left: 0;
    right: 0;
    top: 145px;
    margin: 12px;
  }
  header.header {
    position: relative;
    padding: 70px 0 0 0;
  }
  .nofication_indicate {
    min-width: 30px;
  }
  .inner_body {
    padding: 40px 0;
  }
}

/* resposive end */
